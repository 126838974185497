<template>
  <div class="btn-popup pull-right">
    <b-button v-b-modal.modal-1 variant="primary" @click="clearForm()">{{ categoryType == 'create' ? 'Add Category' : 'Update Category' }}</b-button>
    <b-modal
      id="modal-1"
      ref="cat-modal"
      :title="categoryType == 'create' ? 'Add Category' : 'Update Category'"
      hide-footer
      ok-variant="primary"
    >
      <form class="needs-validation">
        <b-overlay :show="loading" rounded="sm">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Category Title :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              v-model="title"
            />
          </div>
          <div class="form-group mb-0">
            <b-form-checkbox
              id="checkbox-1"
              v-model="show_in_main_page"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Show in main page
            </b-form-checkbox>
          </div>
          <div class="form-group mb-0" style="margin-top:20px;">
            <b-overlay :show="uploadLoading" rounded="sm">
              <label for="validationCustom02" class="mb-1"
                >Category Image :</label
              >
              <input class="form-control" accept="image/*" type="file" @change="uploadPhoto" />
              <div v-if="imageUploaded?.url" class="col-xl-4 xl-50 col-sm-4 col-4">                          
                <img :src="getImgUrl(imageUploaded.url)"
                  class="img-fluid image_zoom_1 blur-up lazyloaded"
                />
              </div>
            </b-overlay>
          </div>
        
        </div>
      </b-overlay>
      </form>
      <b-button class="mt-3" block @click="$bvModal.hide('cat-modal')" variant="primary">Cancel</b-button>
      <b-button class="mt-3" block @click="onSave()" >Save</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GlobalCategoryModal",
  data() {
    return {
      loading: false,
      categoryType: 'create',
      uploadLoading: false,
      catId: null,
      title: null,
      image: null,
      imageUploaded: null,
      show_in_main_page: false
    };
  },
  computed: {
    ...mapState({      
      currentShop: state => state.shop?.currentShop,
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL,
    }),
  },
  methods: {
    clearForm() {
      this.categoryType = 'create';
       this.catId = null;
       this.title = null;
       this.shop = null;
       this.image = null;
       this.imageUploaded = null;
       this.show_in_main_page = false;
    },
    fillForm(item) {
      console.log(item)
      this.categoryType = 'update';
      this.catId = item.id;
      this.title = item.title;
      this.shop = item.shop?.id;
      this.image = item.image?.id ? parseInt(item.image?.id) : null;      
      this.imageUploaded = item.image;
      this.show_in_main_page = item.show_in_main_page;
    },
    getImgUrl(path) {
      return path ? (path.indexOf('https://') != -1 ? path : `${this.BASE_IMAGE_URL}${path}`) : null
    },
    async uploadPhoto({ target: { files = [] } }) {
      if (!files.length) {
          return
      }      
      let file = files[0];      
      this.uploadLoading = true;
      try {
        this.imageUploaded = await this.$store.dispatch("upload/uploadImage", {
          file: file
        });      
        console.log(this.imageUploaded)
        if(this.imageUploaded?.id)
          this.image = this.imageUploaded.id;
        
      } catch (error) {
        console.error(error)
      }
      this.uploadLoading = false;
      
    },
    async onSave() {      
        this.loading = true;
        if(this.categoryType == 'create') {
          await this.$store.dispatch("category/createCategory", {
            title: this.title,
            image: this.image,
            show_in_main_page: this.show_in_main_page,
            shop: this.currentShop?.id
          });      
        }else{
          await this.$store.dispatch("category/updateCategory", {
            id: this.catId,
            data: {
              title: this.title,
              image: this.image,
              show_in_main_page: this.show_in_main_page,
              shop: this.currentShop?.id
            }
          });      
        }
        
        this.$store.dispatch("product/getCategoryList", {
          pagination: {page: 1, pageSize: 50}, 
          filters: {shopId: this.currentShop?.id }
        });
        this.$refs['cat-modal'].hide();
        this.loading = false;
    }
  }
};
</script>

<style>

</style>