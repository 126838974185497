import { apolloClient } from '@/apollo/client'
import uploadImageMutation from '@/apollo/mutation/upload/uploadImage.gql'

const state = {    
  uploadImage: {}
}

const getters = {    

}

const actions = {    
  async uploadImage(context, payload) {
    const { file} = payload
    let data = await apolloClient.mutate({ 
      mutation: uploadImageMutation, 
      variables: { file: file},
      context: { hasUpload: true } 
    }).then(({ data }) => data && data?.upload?.data)
    context.commit('uploadImage', data)
    return {
      id: parseInt(data?.id),
      ...data?.attributes,
    };
 },
}

const mutations = {
  uploadImage: (state, data) => {    
    state.uploadImage = {}
    if (data) {      
      state.uploadImage = {
        id: parseInt(data.id),
        ...data.attributes,
      }      
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}