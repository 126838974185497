import router from '../../router/index.js';
import Userauth from "../../auth/index.js";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    getters: {

    },
    actions: {
        login({ dispatch, commit }, data) {
            commit('loginRequest', data.userInfo?.username);
            commit('loginSuccess', data);
            Userauth.localLogin(data);
            if (data.token) {
                localStorage.setItem('user', JSON.stringify(data));
            }
            router.push("/");
        },

        logout({ commit }) {
            localStorage.removeItem('user');
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    }
}