import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import Breadcrumbs from './components/bread-crumbs.vue'
import VueFeather from 'vue-feather';
import PxCard from './components/pxcard.vue';
import VueApexCharts from 'vue-apexcharts';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
//import CKEditor from "@ckeditor/ckeditor5-vue";
import { VueEditor } from "vue2-editor";
import StarRating from "vue-star-rating";
import GlobalCategoryModal from "./components/modals/globalCategoryModal.vue";
import VueDropzone from 'vue2-dropzone';
import device from 'vue-device-detector-js';
import axios from "axios";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import moment from 'moment';
import Toasted from 'vue-toasted';
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,

} from "vee-validate";
import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
import env from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
// Multi language add
import { defaultLocale, localeOptions } from './constants/config'
import VueI18n from 'vue-i18n';
// import i18n translation languages
import en from './locales/en.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import fr from './locales/fr.json';
import commonTable from './components/commonTable.vue';
import webmms from "./plugins/webmms";

Vue.prototype.mms = webmms;
//vue filters
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);

//Apollo Config
import VueApollo from 'vue-apollo'
import { apolloClient } from './apollo/client';


const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

Vue.use(VueApollo)

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});
localize("en", env);

//Vue.mixin(track);

Vue.use(device);
Vue.use(Toasted, {
    iconPack: 'fontawesome'
});
Vue.component('commonTable', commonTable);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueI18n);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('VueEditor', VueEditor);
Vue.component('VueDropzone', VueDropzone);
Vue.use(VueDropzone);
Vue.component('GlobalCategoryModal', GlobalCategoryModal)
Vue.component('StarRating', StarRating);
Vue.use(VueEditor);
//Vue.use(CKEditor);
Vue.config.productionTip = false
Vue.use(BootstrapVue);
import './assets/scss/app.scss'
import './assets/scss/admin.scss'
Vue.component('Breadcrumbs', Breadcrumbs);
Vue.use(VueFeather);
Vue.use(require('vue-chartist'))
Vue.component(PxCard.name, PxCard)

const messages = { en: en, es: es, pt: pt, fr: fr };
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;
const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true
});

Vue.prototype.currentDate = (iso) => {
    if(!iso)
      return moment(new Date()).format('YYYY-MM-DD')
    else
      return moment(new Date()).toISOString()
  }

Vue.prototype.currentTime = () => {
return moment(new Date()).format('YYYY-MM-DD hh:mm:ss');
}

Vue.prototype.isInLastMinutes = (start) => {
    try {
      if(!start)
        return false;
      let startTime = moment(start);
      let endTime = moment(new Date());
      let duration = moment.duration(endTime.diff(startTime)).asMinutes();
      let minutes = Math.floor(duration);
      if(minutes <= 0)
        return true;
      else
        return false;
    } catch (error) {
        return false;
    }
}

Vue.prototype.deviceInfo = async function(){
    let result = {};

    result.model = this.$device.model;
    result.brand = this.$device.brand;
    result.type = this.$device.type;
    result.os_name = this.$device.os_name;
    result.os_platform = this.$device.os_platform;
    result.os_version = this.$device.os_version;
    result.browser_name = this.$device.browser_name;
    result.browser_version = this.$device.browser_version;
    result.browser_engine = this.$device.browser_engine;
    result.browser_engine_version = this.$device.browser_engine_version;
    result.isMobile = this.$device.isMobile;
    result.isTablet = this.$device.isTablet;
    result.isDesktop = this.$device.isDesktop;

    let getIP = await axios.get('https://jsonip.com/');
    if(getIP.status == 200)
      result.IP = getIP.data.ip;

    return result;
}

new Vue({
    router,
    store,
    i18n,
    apolloProvider,
    render: h => h(App)
}).$mount('#app')