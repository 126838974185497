<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Product" title="Sub Category" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Sub Category</h5>
            </div>
            <div class="card-body">
              <SubCategoryModal ref="subcat-dialog" />
              <div class="table-responsive datatable-vue product-physical">
                <b-modal ref="subcat-delete-modal" id="modal-2" title="Confirmation" hide-footer>
                  <b-overlay :show="deleteLoading" rounded="sm">
                    <p class="my-4">Are you sure!</p>
                    <b-button class="mt-3" @click="deleteRow(selectedSku)" variant="primary">Delete</b-button>
                  </b-overlay>
                </b-modal>
                <b-overlay :show="loading" rounded="sm">
                <b-table
                  striped
                  hover
                  class="text-center"
                  bordered
                  show-empty
                  stacked="md"
                  head-variant="light"
                  :items="items"
                  :fields="tablefields"
                  :current-page="currentPage"
                  :per-page="0"
                  :select-mode="selectMode"
                  :selectable="true"
                >
                  <template #cell(status)="field">
                    <statusIcon :field="field"></statusIcon>
                  </template>
                  <template v-slot:head(actions)>
                    <feather
                      type="plus"
                      stroke="#3758FD"
                      stroke-width="6"
                      size="22px"
                      fill="#3758FD"
                    ></feather>
                  </template>
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="edit != item.id">{{ value }}</template>
                    <b-form-input type="text" v-else v-model="item[key]" />
                  </template>
                  <template v-slot:cell(actions)="{ item: item }">
                    <span
                      style="font-size: 20px; color: blue;"
                      v-b-modal.modal-1
                      @click="onEdit(item)"
                    >
                      <feather
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                      ></feather>
                    </span>
                    <span
                      v-b-modal.modal-2
                      @click="getIndex(item.id)"
                      style="font-size: 20px; color: #FA399F;"
                    >
                      <feather
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                      ></feather>
                    </span>
                  </template>
                  <template v-slot:head(actions)>

                  </template>
                </b-table>
                </b-overlay>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="changePage"
                    aria-controls="my-table"
                    class="mt-4"
                  ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import SubCategoryModal from "./AddSubCategory.vue";
import track from '@/mixins/track';
export default {
  name: 'SubCategory',
  mixins: [track],
  components: { statusIcon, SubCategoryModal },
  data() {
    return {
      loading: false,
      deleteLoading: false,
      tablefields: [
        { key: "title", label: "Title", sortable: false },
        { key: "actions" }
      ],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: "",
      selectedSku : "",
      index: null,
      selectMode: "single"
    };
  },
  async created() {
    if(this.$route.params.catId)
        await this.loadSubCategoryList()
  },
  watch: {
    "$route.params.catId": async() => {
      if(this.$route.params.catId)
        await this.loadSubCategoryList()
    }
  },
  computed: {
    ...mapState({
      currentShop: state => state.shop?.currentShop,
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL,
      category: state => state.product?.subCategoryList?.[0],
      items: state => state.product?.subCategoryList?.data || [],
      paganiation: state => state.product?.subCategoryList?.pagination,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    selectedRows() {
      return this.items.filter(item => item.delete);
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.paganiation?.total || 0;
  },
  methods: {
    getImgUrl(path) {
      return path ? (path.indexOf('https://') != -1 ? path : `${this.BASE_IMAGE_URL}${path}`) : null
    },
    async loadSubCategoryList(){
      this.loading = true;
      await this.$store.dispatch("product/getSubCategoryList", {
      pagination: {page: this.currentPage, pageSize: this.perPage},
      filters: {catId: this.$route.params.catId }
      });
      this.totalRows = this.paganiation?.total || 0;
      this.loading = false;
    },
    async changePage(page){
      this.currentPage = page;
      await this.loadSubCategoryList();
    },
    formData(e) {
      console.log("modal data", e);
    },
    getIndex(id) {
      this.selectedSku = id
    },
    async deleteRow(id) {
      this.deleteLoading = true;
      const result = await this.$store.dispatch("subcategory/deleteSubCategory", {
        id: id
      });
      this.deleteLoading = false;
      this.loadSubCategoryList();
      this.$refs['subcat-delete-modal'].hide();
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    onEdit(item) {
      this.$refs['subcat-dialog']?.fillForm(item)
    },
    searchData() {
      this.add = false;
      this.items.splice(0, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>