<template>
  <div class="btn-popup pull-right">
    <b-button v-b-modal.modal-1 :variant="categoryType == 'digital' ? 'primary' : 'primary'">Add Category</b-button>
    <b-modal
      id="modal-1"
      ref="cat-modal"
      :title="'Add Category'"
      hide-footer
      ok-variant="primary"
    >
      <form class="needs-validation">
        <b-overlay :show="loading" rounded="sm">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">Category Title :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              v-model="title"
            />
          </div>
          <div class="form-group mb-0">
            <b-form-checkbox
              id="checkbox-1"
              v-model="show_in_main_page"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              Show in main page
            </b-form-checkbox>
          </div>
          <div class="form-group mb-0">
            <b-overlay :show="uploadLoading" rounded="sm">
              <label for="validationCustom02" class="mb-1"
                >Category Image :</label
              >
              <input class="form-control" accept="image/*" type="file" @change="uploadPhoto" />
              <div v-if="imageUploaded?.url" class="col-xl-4 xl-50 col-sm-4 col-4">                          
                <img
                  :src="APP_URL + imageUploaded.url"
                  class="img-fluid image_zoom_1 blur-up lazyloaded"
                />
              </div>
            </b-overlay>
          </div>
        
        </div>
      </b-overlay>
      </form>
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Cancel</b-button>
      <b-button class="mt-3" block @click="onSave()" variant="primary">Save</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GlobalCategoryModal",
  props:["categoryType"],
  data() {
    return {
      loading: false,
      uploadLoading: false,
      title: null,
      image: null,
      imageUploaded: null,
      show_in_main_page: false
    };
  },
  computed: {
    ...mapState({      
      currentShop: state => state.shop?.currentShop,
      APP_URL: state => state.APP_URL
    }),
  },
  methods: {
    async uploadPhoto({ target: { files = [] } }) {
      if (!files.length) {
          return
      }      
      let file = files[0];      
      this.uploadLoading = true;
      try {
        this.imageUploaded = await this.$store.dispatch("upload/uploadImage", {
          file: file
        });      
        console.log(this.imageUploaded)
        if(this.imageUploaded?.id)
          this.image = this.imageUploaded.id;
        
      } catch (error) {
        console.error(error)
      }
      this.uploadLoading = false;
      
    },
    async onSave() {      
        this.loading = true;
        const result = await this.$store.dispatch("category/createCategory", {
          title: this.title,
          image: this.image,
          show_in_main_page: this.show_in_main_page,
          shop: this.currentShop?.id
        });      
        this.$store.dispatch("product/getCategoryList", {
          pagination: {page: 1, pageSize: 50}, 
          filters: {shopId: this.currentShop?.id }
        });
        this.$refs['cat-modal'].hide();
        this.loading = false;
    }
  }
};
</script>

<style>

</style>