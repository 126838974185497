import { ApolloClient } from 'apollo-client';
// import { createHttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink, concat, split } from "apollo-link";
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from '@/config/config';

// Here we define the ling to our GraphQL endpoint
const httpLink = createUploadLink({
  uri: config.APOLLO_GRAPHQL_URL
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  let user = localStorage.getItem("user");
  if(user)
    user = JSON.parse(user);
  operation.setContext({
    headers: {
      authorization: user?.token ? `Bearer ${user.token}` : "",
    },
  });    
  return forward(operation);
});

// Even though we're not going to use Apollo cache at all, we still need to provide it when creating Apollo Client instance
const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  // We disable the Apollo cache here because we use Vuex as a single source of truth
  defaultOptions: {
    query: {
      //fetchPolicy: 'network-only'
    }
  }
});
