import { apolloClient } from '@/apollo/client'
import createCategoryMutation from '@/apollo/mutation/category/createCategory.gql'
import updateCategoryMutation from '@/apollo/mutation/category/updateCategory.gql'
import deleteCategoryMutation from '@/apollo/mutation/category/deleteCategory.gql'

const state = {    
  
}

const getters = {    

}

const actions = {    
  async createCategory(context, payload) {
    let data = await apolloClient.mutate({ 
      mutation: createCategoryMutation, 
      variables: { data: payload } 
    }).then(({ data }) => data && data?.createCategory?.data)
    return {
      id: parseInt(data?.id),
      ...data?.attributes,
    };
 },
 async updateCategory(context, payload) {
  let { id, data } = payload;
  let result = await apolloClient.mutate({ 
    mutation: updateCategoryMutation, 
    variables: { id: id, data: data } 
  }).then(({ data }) => data && data?.updateCategory?.data)
  return {
    id: parseInt(result?.id),
    ...result?.attributes,
  };
},
 async deleteCategory(context, payload) {
  let { id } = payload;
  let data = await apolloClient.mutate({ 
    mutation: deleteCategoryMutation, 
    variables: { id: id } 
  }).then(({ data }) => data && data?.deleteCategory?.data)
  return {
    id: parseInt(data?.id),
    ...data?.attributes,
  };
},
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}