<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 footer-copyright">
          <p class="mb-0">Copyright 2019 © Shoppu All rights reserved.</p>
        </div>
        <div class="col-md-6">
          <p class="pull-right mb-0">
            Hand crafted & made with <i class="fa fa-heart"></i>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>

</style>