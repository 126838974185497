/* eslint-disable indent */
import { mapState } from 'vuex'
import { set as setCookie, get as getCookie, remove as removeCookie } from "es-cookie";
import conf from '@/config/config';

export default {
  computed: {
    ...mapState({
      currentShop: state => state.shop?.currentShop,
      user: state => state.authentication.user?.userInfo
    }),
  },
  watch: {
    '$store.state.mmsReady'(val, oldVal) {
      if(val && val != oldVal && oldVal != undefined && process.browser){
        this.track();
      }else{
      }
    }
  },
  methods: {
    async track() {
      try {
        if(conf?.TRACK?.enable_track != 1)
          return;
        const cookieKey = `sys-log-${conf?.TRACK?.product?.replace(/\./g, '')}-${this.$options?.name?.replace(/\./g, '')}`;
        let oldDate = getCookie(cookieKey);

        try{
          if(this.isInLastMinutes(oldDate))
            return;
        }catch(err){

        }

        let device = await this.deviceInfo();
        let ypuid = null;
        if(this.user)
          ypuid = this.user.uid;
        const currentTime = this.currentTime();
        let payload = {
            App: {
              product: conf?.TRACK?.product,
              app_id: this.currentShop?.app_id,              
              brick: conf?.TRACK?.brick,
              click: null
            },
            Meta: {
              Date: currentTime,
              User: {
               Uid: ypuid
              },
              Device: device,
              Page: {
               URL: this.$route.path
              }
            }
        };

          let sysLog = await this.mms.sendMMS({
            DDN: conf?.TRACK?.track_ddn,
            topic: conf?.TRACK?.track_topic,
            payload: payload
          });
          let sysResult;
          if (typeof res == 'object')
            sysResult = sysLog;
          else
            sysResult = sysLog[0];
          if (Array.isArray(sysResult))
            sysResult = sysResult[0];
          // console.log(payload);
          // console.log(sysResult);
          if (sysResult.Reply.ErrMsg == 'OK') {
            let currentDate = this.currentDate(true);
            setCookie(cookieKey, currentDate);
          }else{
            console.log(sysResult);
          }

      } catch (error) {
          console.log(error);
      }
    }
  }
}
