<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Physical" title="Edit Shop" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>Edit Shop</h5>
            </div>
            <div class="card-body">
              <b-overlay :show="shopLoading || !currentShop" rounded="sm">
              <div class="row product-adding">
                <div class="col-xl-5">
                  <div class="add-product">
                    <b-overlay :show="uploadLogoLoading" rounded="sm">
                    <div class="row" style="margin-bottom: 15px;">
                      <div class="col-xl-9 xl-50 col-sm-6 col-9">
                          <!--src="../../assets/images/pro3/33.jpg"-->
                        <img
                          :src="Logo"
                          class="img-fluid image_zoom_1 blur-up lazyloaded"
                        />
                      </div>
                      <div class="col-xl-3 xl-50 col-sm-6 col-3">
                        <ul class="file-upload-product">
                          <li>
                            <div class="box-input-file">
                              <input
                                accept="image/*"
                                class="upload"
                                type="file"
                                @change="uploadLogo"
                              />
                              <feather type="plus"></feather>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-overlay>
                  </div>
                  <div class="add-product">
                    <b-overlay :show="uploadShopLogoLoading" rounded="sm">
                    <div class="row" style="margin-bottom: 15px;">
                      <div class="col-xl-9 xl-50 col-sm-6 col-9">
                          <!--src="../../assets/images/pro3/33.jpg"-->
                        <img
                          :src="shopLogo"
                          class="img-fluid image_zoom_1 blur-up lazyloaded"
                        />
                      </div>
                      <div class="col-xl-3 xl-50 col-sm-6 col-3">
                        <ul class="file-upload-product">
                          <li>
                            <div class="box-input-file">
                              <input
                                accept="image/*"
                                class="upload"
                                type="file"
                                @change="uploadShopLogo"
                              />
                              <feather type="plus"></feather>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-overlay>
                  </div>
                  <div class="add-product">
                    <b-overlay :show="uploadStoryLoading" rounded="sm">
                    <div class="row">
                      <div class="col-xl-9 xl-50 col-sm-6 col-9">
                          <!--src="../../assets/images/pro3/33.jpg"-->
                        <img
                          :src="shopStory"
                          class="img-fluid image_zoom_1 blur-up lazyloaded"
                        />
                      </div>
                      <div class="col-xl-3 xl-50 col-sm-6 col-3">
                        <ul class="file-upload-product">
                          <li>
                            <div class="box-input-file">
                              <input
                                accept="image/*"
                                class="upload"
                                type="file"
                                @change="uploadStory"
                              />
                              <feather type="plus"></feather>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-overlay>
                  </div>
                </div>
                <div class="col-xl-7">
                  <b-overlay :show="loading" rounded="sm">
                  <form class="needs-validation add-product-form" novalidate="">
                    <div class="form">
                      <div class="form-group mb-3 row">
                        <label
                          class="col-xl-3 col-sm-4 mb-0"
                          >App Id :</label
                        >
                        <label class="col-xl-8 col-sm-7">
                          {{ model.app_id }}
                        </label>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom01"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Title :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom01"
                          type="text"
                          required=""
                          v-model="model.title"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom01"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Theme :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom01"
                          type="text"
                          required=""
                          v-model="model.theme"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom01"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Copyright :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom01"
                          type="text"
                          required=""
                          v-model="model.copyright"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Tax Code :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.tax_code"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Story Title :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.story_title"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Story Body :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.story_body"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustomUsername"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Official Website :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustomUsername"
                          type="text"
                          required=""
                          v-model="model.official_website"
                        />
                        <div class="invalid-feedback offset-sm-4 offset-xl-3">
                          Please choose Valid Code.
                        </div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Social Facebook :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.social_facebook"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Social Lineid :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.social_lineid"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Social Twitter :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.social_twitter"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Social Instagram :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.social_instagram"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Social Shop :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.other_link_ec1"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Social Telegram :</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.other_link_ec2"
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >CRM Note 1:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.crm_note1"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >CRM Note 2:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.crm_note2"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >CRM Note 3:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.crm_note3"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Traffic Info:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.traffic_info"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Store Location:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.store_location"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Store Hours:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.store_hours"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Contact Email:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.contact_email"
                        />
                      </div>
                      <div class="form-group mb-3 row">
                        <label
                          for="validationCustom02"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Contact Phone:</label
                        >
                        <input
                          class="form-control col-xl-8 col-sm-7"
                          id="validationCustom02"
                          type="text"
                          required=""
                          v-model="model.contact_phone"
                        />
                      </div>
                    </div>
                    <div class="form">
                      <!-- <div class="form-group row">
                        <label
                          for="exampleFormControlSelect1"
                          class="col-xl-3 col-sm-4 mb-0"
                          >Select Size :</label
                        >
                        <select
                          class="form-control digits col-xl-8 col-sm-7"
                          id="exampleFormControlSelect1"
                        >
                          <option>Small</option>
                          <option>Medium</option>
                          <option>Large</option>
                          <option>Extra Large</option>
                        </select>
                      </div>
                      <div class="form-group row">
                        <label class="col-xl-3 col-sm-4 mb-0"
                          >Total Products :</label
                        >
                        <fieldset
                          class="qty-box col-xl-9 col-xl-8 col-sm-7 pl-0 qty-responsive"
                        >
                          <div class="input-group">
                            <b-input-group-prepend>
                              <b-btn variant="primary" @click="decrement"
                                >-</b-btn
                              >
                            </b-input-group-prepend>
                            <b-input
                              type="text"
                              name="quantity"
                              class="form-control input-number"
                              v-model="counter"
                            />
                            <b-input-group-append>
                              <b-btn variant="primary" @click="counter++"
                                >+</b-btn
                              >
                            </b-input-group-append>
                          </div>
                        </fieldset>
                      </div> -->
                      <div class="form-group row">
                        <label class="col-xl-3 col-sm-4"
                          >About :</label
                        >
                        <textarea
                           v-model="model.about"
                           rows="5"
                           cols="12"
                           class="form-control col-xl-8 col-sm-7"
                           ></textarea>
                      </div>
                    </div>
                    <div class="offset-xl-3 offset-sm-4">
                      <button type="button" class="btn btn-primary" @click="onSave">Edit</button>
                    </div>
                  </form>
                  </b-overlay>
                </div>
              </div>
             </b-overlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import track from '@/mixins/track';
export default {
  name: 'ShopDetail',
  mixins: [track],
  data() {
    return {
      loading: false,
      shopLoading: false,
      uploadLogoLoading: false,
      uploadShopLogoLoading: false,
      uploadStoryLoading: false,
      counter: 0,
      image: [],
      model: {},
      logoUploaded: null,
      shopLogoUploaded: null,
      storyUploaded: null
    };
  },
  watch: {
    currentShop(){
      this.shopLoading = true;
      this.model = {...this.currentShop}
      let $this = this;
      setTimeout(() => {
        $this.shopLoading = false;
      }, 500);
    },
    mmsReady: {
       immediate: true,
       handler(val, oldVal){
        console.log(val);
       }
     }
  },
  created() {
    this.model = {...this.currentShop}
  },
  computed: {
    ...mapState({
      user: state => state.authentication.user?.userInfo,
      currentShop: state => state.shop?.currentShop,
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL,
    }),
    mmsReady() {
      return this.$mmsStatus?.mmsReady;
    },
    Logo() {
      return this.model?.logo?.url ? (this.model?.logo?.url.indexOf('https://') != -1 ? this.model?.logo?.url : `${this.BASE_IMAGE_URL}${this.model?.logo?.url}`) : null
    },
    shopLogo() {
      return this.model?.shop_logo?.url ? (this.model?.shop_logo?.url.indexOf('https://') != -1 ? this.model?.shop_logo?.url : `${this.BASE_IMAGE_URL}${this.model?.shop_logo?.url}`) : null
    },
    shopStory() {
      return this.model?.story?.url ? (this.model?.story?.url.indexOf('https://') != -1 ? this.model?.story?.url : `${this.BASE_IMAGE_URL}${this.model?.story?.url}`) : null
    }
  },
  methods: {
    decrement() {
      if (this.counter > 1) this.counter--;
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(index) {
      console.log("index", index);
      this.image.splice(index, 1);
      //  this.image = "";
    },
    async onSave() {
        this.loading = true;
        await this.$store.dispatch("shop/updateShop", {
            id: this.currentShop?.id,
            data: {
              title: this.model.title,
              theme: this.model.theme,
              copyright: this.model.copyright,
              story_title: this.model.story_title,
              story_body: this.model.story_body,
              tax_code: this.model.tax_code,
              official_website: this.model.official_website,
              social_facebook: this.model.social_facebook,
              social_lineid: this.model.social_lineid,
              social_twitter: this.model.social_twitter,
              social_instagram: this.model.social_instagram,
              other_link_ec1: this.model.other_link_ec1,
              other_link_ec2: this.model.other_link_ec2,
              crm_note1: this.model.crm_note1,
              crm_note2: this.model.crm_note2,
              crm_note3: this.model.crm_note3,
              store_location: this.model.store_location,
              store_hours: this.model.store_hours,
              contact_email: this.model.contact_email,
              contact_phone: this.model.contact_phone,
              traffic_info: this.model.traffic_info,
              about: this.model.about,
              logo: this.logoUploaded?.id || this.model.logo?.id,
              shop_logo: this.shopLogoUploaded?.id || this.model.shop_logo?.id,
              story: this.storyUploaded?.id || this.model.story?.id
            }
        });
        this.loading = false;
        this.logoUploaded = null;
        this.shopLogoUploaded = null;
        this.storyUploaded = null;
    },
    async uploadLogo({ target: { files = [] } }) {
      if (!files.length) {
          return
      }
      let file = files[0];
      this.uploadLogoLoading = true;
      try {
        this.logoUploaded = await this.$store.dispatch("upload/uploadImage", {
          file: file
        });
        if(this.logoUploaded?.id){
          if(!this.model.logo)
            this.model.logo = {};
          this.model.logo.url = this.logoUploaded.url;
        }


      } catch (error) {
        console.error(error)
      }
      this.uploadLogoLoading = false;

    },
    async uploadShopLogo({ target: { files = [] } }) {
      if (!files.length) {
          return
      }
      let file = files[0];
      this.uploadShopLogoLoading = true;
      try {
        this.shopLogoUploaded = await this.$store.dispatch("upload/uploadImage", {
          file: file
        });
        if(this.shopLogoUploaded?.id){
          if(!this.model.shop_logo)
            this.model.shop_logo = {};
          this.model.shop_logo.url = this.shopLogoUploaded.url;
        }


      } catch (error) {
        console.error(error)
      }
      this.uploadShopLogoLoading = false;

    },
    async uploadStory({ target: { files = [] } }) {
      if (!files.length) {
          return
      }
      let file = files[0];
      this.uploadStoryLoading = true;
      try {
        this.storyUploaded = await this.$store.dispatch("upload/uploadImage", {
          file: file
        });
        if(this.storyUploaded?.id){
          if(!this.model.story)
            this.model.story = {};
          this.model.story.url = this.storyUploaded.url;
        }

      } catch (error) {
        console.error(error)
      }
      this.uploadStoryLoading = false;

    },
  }
};
</script>

<style scoped>
 .ck-content { height:500px; }
 .icon{
position: absolute;
  top: 8px;
  right: 16px;
 }
</style>