<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Product" title="Product List" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Product Lists</h5>
            </div>
            <div class="card-body">
              <ProductModal ref="product-dialog" />
              <div class="table-responsive datatable-vue">
                <div class="row">
                    <div class="col-md-6">
                      Select Category
                      <b-form-select style="margin-bottom:20px" @change="changeSelectCategory" v-model="categorySelected" :options="categories" size="sm" class="mt-3">
                      </b-form-select>
                    </div>
                    <div  class="col-md-6">
                      Select Sub Category
                      <b-form-select style="margin-bottom:20px" @change="loadProductList" v-model="subCategorySelected" :options="subCategories" size="sm" class="mt-3">
                      </b-form-select>
                    </div>
                </div>
                <b-modal ref="product-delete-modal" id="modal-2" title="Confirmation" hide-footer>
                  <b-overlay :show="deleteLoading" rounded="sm">
                    <p class="my-4">Are you sure!</p>
                    <b-button class="mt-3" @click="deleteRow(selectedSku)" variant="primary">Delete</b-button>
                  </b-overlay>
                </b-modal>
                <b-overlay :show="loading" rounded="sm">
                <b-table
                  striped
                  hover
                  class="text-center"
                  bordered
                  head-variant="light"
                  show-empty
                  stacked="md"
                  :items="productList"
                  :fields="tablefields"
                  :current-page="currentPage"
                  :per-page="0"
                  :select-mode="selectMode"
                >
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="edit != item.id">{{ value }}</template>
                    <b-form-input type="text" v-else v-model="item[key]" />
                  </template>
                  <!-- <template #cell(status) v-for="(item, index) in productList">
                    <feather
                      v-if="item.status == true"
                      :key="index"
                      type="circle"
                      stroke="#81BA01"
                      stroke-width="1"
                      size="10px"
                      fill="#81BA01"
                      stroke-linejoin="round"
                    ></feather>

                    <feather
                      v-else-if="item.status == false"
                      :key="index"
                      type="circle"
                      stroke="#FBBC58"
                      stroke-width="1"
                      size="10px"
                      fill="#FBBC58"
                      stroke-linejoin="round"
                    ></feather>
                  </template> -->
                 <template #cell(image)="field">
                    <img
                      height="50px"
                      :src="getImgUrl(field.item.images?.[0]?.url)"
                      width="50px"
                    />
                  </template>
                  <template #cell(price)="field">
                    {{ field.item?.price | currency('$', 0) }}
                  </template>
                  <template v-slot:cell(actions)="{ item: item }">
                    <span
                      style="font-size: 20px; color: blue;"
                      v-b-modal.modal-product-1
                      @click="onEdit(item)"
                    >
                      <feather
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                      ></feather>
                    </span>
                    <span
                      v-b-modal.modal-2
                      @click="getIndex(item.id)"
                      style="font-size: 20px; color: #FA399F;"
                    >
                      <feather
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                      ></feather>
                    </span>
                  </template>
                  <template v-slot:head(actions)>

                  </template>
                </b-table>
              </b-overlay>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="changePage"
                    aria-controls="my-table"
                    class="mt-4"
                  ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ProductModal from "./AddProduct.vue";
import track from '@/mixins/track';
export default {
  name: 'ProductList',
  mixins: [track],
  data() {
    return {
      loading: false,
      deleteLoading: false,
      tablefields: [
        { key: "id", label: "Id", sortable: false },
        { key: "image", label: "Product", sortable: false },
        { key: "title", label: "Product Title", sortable: false },
        { key: "price", label: "Price", sortable: false },
        { key: "actions" }
      ],
      categorySelected: null,
      subCategorySelected: null,
      filter: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: "",
      selectedSku : "",
      index: null,
      selectMode: "single"
    };
  },
  components: {
    ProductModal
  },
  async created() {
    if(this.currentShop?.id){
      await this.loadProductList()
      await this.loadCategoryList()
    }
  },
  watch: {
    currentShop(){
      if(this.currentShop?.id){
        this.loadProductList()
        this.loadCategoryList()
      }

    }
  },
  computed: {
    ...mapState({
      currentShop: state => state.shop?.currentShop,
      categories: state => [{ value: null, text: 'All Categories' }, ...state.product?.categoryList?.data?.map(item => { return { value: item, text: item.title }})],
      subCategories: state => [{ value: null, text: 'All Sub Categories' }, ...state.product?.subCategoryList?.data?.map(item => { return { value: item, text: item.title }})],
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL,
      productList: state => state.product?.productList?.data || [],
      productPaganiation: state => state.product?.productList?.pagination,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    selectedRows() {
      return this.productList.filter(item => item.delete);
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.productPaganiation?.total || 0;
  },
  methods: {
    getIndex(id) {
      this.selectedSku = id
    },
    async loadCategoryList(){
      this.categorySelected = null;
      await this.$store.dispatch("product/getCategoryList", {
      pagination: {page: 1, pageSize: 1000},
      filters: {shopId: this.currentShop?.id }
      });
    },
    async loadSubCategoryList(){
      this.subCategorySelected = null;
      await this.$store.dispatch("product/getSubCategoryList", {
      pagination: {page: 1, pageSize: 1000},
      filters: {
        catId: this.categorySelected?.id
       }
     });
    },
    async loadProductList(){
      this.loading = true;
      await this.$store.dispatch("product/getProductList", {
      pagination: {page: this.currentPage, pageSize: this.perPage},
      filters: {
        shopId: this.currentShop?.id,
        ...this.categorySelected ? { catId: this.categorySelected?.id } : {},
        ...this.subCategorySelected ? { subCatId: this.subCategorySelected?.id } : {}
      }
      });
      this.totalRows = this.productPaganiation?.total || 0;
      console.log(this.productList)
      this.loading = false;
    },
    async changePage(page){
      this.currentPage = page;
      await this.loadProductList();
    },
    async changeSelectCategory(){
       await this.loadSubCategoryList();
       await this.loadProductList();
    },
   async deleteRow(id) {
      this.deleteLoading = true;
      const result = await this.$store.dispatch("product/deleteProduct", {
        id: id
      });
      this.deleteLoading = false;
      this.loadProductList();
      this.$refs['product-delete-modal'].hide();
    },
    getImgUrl(path) {
      return path ? (path.indexOf('https://') != -1 ? path : `${this.BASE_IMAGE_URL}${path}`) : null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    onEdit(item) {
      this.$refs['product-dialog']?.fillForm(item)
    }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>