import Vue from 'vue'
import Vuex from 'vuex'
import employee from './modules/employee.js'
import menu from './modules/menu.js'
import shop from './modules/shop.js'
import order from './modules/order.js'
import product from './modules/product.js'
import category from './modules/category.js'
import subcategory from './modules/subcategory.js'
import upload from './modules/upload.js'
import digitalProducts from './modules/digital-products.js';
import coupon from './modules/coupon.js';
import pages from './modules/pages.js'
import media from './modules/media.js'
import user from './modules/user.js'
import vendor from './modules/vendor.js'
import localization from './modules/localization.js'
import invoice from './modules/invoice.js'
import { authentication } from './modules/authentication.js'
import reports from './modules/reports.js';
import config from '@/config/config'
import VuexPersistence from "vuex-persist"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: [

    ]
})

export default new Vuex.Store({
    state: {
        returnUrl: null,
        APP_URL: config.APP_URL,
        BASE_IMAGE_URL: config.BASE_IMAGE_URL,
        mmsReady: false
    },
    mutations: {
        changeLang(state, payload) {
            localStorage.setItem('currentLanguage', payload.id)
            localStorage.setItem('currentLanguageIcon', payload.icon)
            window.location.reload();
        },
        setReturnUrl(state, url){
            state.returnUrl = url;
        },
        clearReturnUrl(state){
            state.returnUrl = null;
        },
        changeMMSReady(state, status) {
            console.log("setState = ", status);
            state.mmsReady = status;
        }
    },
    getters: {
        returnUrl(state) {
            return state.returnUrl;
        },
    },
    actions: {
        setLang({ commit }, payload) {
            commit('changeLang', payload)
        },
        clearReturnUrl({ commit}){
            localStorage.removeItem('ReturnUrl');
            commit('clearReturnUrl');
        },
        setMMSReady({commit}, status) {
            commit('changeMMSReady', status);
        }
    },
    modules: {
        menu,
        shop,
        product,
        category,
        subcategory,
        upload,
        order,
        employee,
        digitalProducts,
        coupon,
        pages,
        media,
        user,
        vendor,
        localization,
        invoice,
        authentication,
        reports
    },
    plugins: [vuexLocal.plugin]
})