<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",  
  computed: {
    ...mapState({      
      user: state => state.authentication.user?.userInfo,
      shops: state => state.shop?.shops      
    })
  },  
  async created() {       
    const shops = this.user?.shops;
    if(shops?.length > 0)
      await this.$store.dispatch('shop/loadShops', shops);    
  }
}
</script>
<style>

</style>
