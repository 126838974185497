import { apolloClient } from '@/apollo/client'
import shopQuery from '@/apollo/query/shop/shop.gql'
import updateShopMutation from '@/apollo/mutation/shop/updateShop.gql'

const state = () => ({
  shops: [],
  currentShop: null
})

const getters = {
  
}

const mutations = {
  loadShops: (state, data) => {    
    state.shops = []
    if (data?.length) {      
      state.shops = data.map(item => {        
        return {
          id: parseInt(item.id),
          ...item.attributes,
          ...item.attributes?.zip_code?.data ? { zip_code: {id: item.attributes.zip_code.data.id, ...item.attributes.zip_code.data.attributes}} : {},
          ...item.attributes?.logo?.data ? { logo: {id: item.attributes.logo.data.id, ...item.attributes.logo.data.attributes}} : {},
          ...item.attributes?.shop_logo?.data ? { shop_logo: {id: item.attributes.shop_logo.data.id, ...item.attributes.shop_logo.data.attributes}} : {},
          ...item.attributes?.story?.data ? { story: {id: item.attributes.story.data.id, ...item.attributes.story.data.attributes}} : {},
          ...item.attributes?.sliders?.data ? { sliders: [...item.attributes.sliders.data.map(p => { return {id: p.id, ...p.attributes} })] } : {},
        }
      });    
      if(!state.currentShop)
        state.currentShop = state.shops[0]
    }
  },
  setCurrentShop: (state, data) => {
    state.currentShop = data;
  }
}

const actions = {
  async loadShops(context, payload) {            
    let data = await apolloClient.query({ 
      query: shopQuery, 
      variables: { ids: payload}, 
      fetchPolicy: 'no-cache' 
    }).then(({ data }) => data && data?.shops?.data)    
    context.commit('loadShops', data)
  },
  async updateShop(context, payload) {
    let { id, data } = payload;
    let result = await apolloClient.mutate({ 
      mutation: updateShopMutation, 
      variables: { id: id, data: data } 
    }).then(({ data }) => data && data?.updateShop?.data)
    return {
      id: parseInt(result?.id),
      ...result?.attributes,
    };
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
