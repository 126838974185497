import Vue from 'vue'
import VueRouter from 'vue-router'
import Body from '../components/Body.vue';
import dashboard from '../pages/dashboard.vue';
import shopDetail from '../pages/shop/ShopDetail.vue';
import category from '../pages/physical/category';
import AddProduct from '../pages/physical/AddProduct';
import SubCategory from '../pages/physical/SubCategory.vue';
import ProductList from '../pages/physical/ProductList.vue';
import ProductDetail from '../pages/physical/ProductDetail.vue';
import CategoryDigital from '../pages/digital/Category.vue';
import SubCategoryDigital from '../pages/digital/SubCategory.vue';
import ProductListDigital from '../pages/digital/ProductList.vue';
import AddProductDigital from '../pages/digital/AddProduct.vue';
import Order from '../pages/sales/order.vue';
import transactions from '../pages/sales/Transaction.vue';
import CouponList from '../pages/coupon/CouponList.vue';
import AddCoupon from '../pages/coupon/AddCoupon.vue';
import PageList from '../pages/page/PageList.vue';
import CreatePage from '../pages/page/create-page.vue';
import Media from '../pages/media.vue';
import MenuList from '../pages/menus/menu-list.vue';
import CreateMenu from '../pages/menus/create-menu.vue';
import UserList from '../pages/users/user-list.vue';
import CreateUser from '../pages/users/create-user.vue';
import VendorList from '../pages/vendors/vendor-list.vue';
import CreateVendor from '../pages/vendors/create-vendor.vue';
import LocalTranslation from '../pages/localization/translations.vue';
import CurrencyRates from '../pages/localization/currency-rates.vue'
import Taxes from '../pages/localization/taxes.vue';
import Reports from '../pages/reports.vue';
import Profile from '../pages/profiles/profile.vue';
import Invoice from '../pages/invoice.vue';
import Auth from '../pages/authentication/index.vue';
import AuthCallback from '../pages/authentication/callback.vue';
import Userauth from '../auth/index.js'
import conf from'@/config/config';
import store from '@/store';
const LoginUrl = conf.loginUrl;

Vue.use(VueRouter)

const routes = [    
    {
        path: '/',
        component: Body,
        children: [{
            path: '/',
            name: 'default',
            component: shopDetail,
            meta: {
                title: 'Shop Detail | Shoppu Dashboard',
            }
        }]
    },
    {
        path: '/shop',
        component: Body,
        children: [{
                path: 'detail',
                name: 'shopdetail',
                component: shopDetail,
                meta: {
                    title: 'Shop Detail | Shoppu Dashboard',
                }
            },
        ]
    },
    {
        path: '/physical',
        component: Body,
        children: [{
                path: 'category',
                name: 'category',
                component: category,
                meta: {
                    title: 'Category | Shoppu Dashboard',
                }
            },
            {
                path: 'add-product',
                name: 'add-product',
                component: AddProduct,
                meta: {
                    title: 'Product | Shoppu Dashboard',
                }
            },
            // {
            //     path: 'sub-category',
            //     name: 'sub-category',
            //     component: SubCategory,
            //     meta: {
            //         title: 'Sub Category | Shoppu Dashboard',
            //     }
            // },
            {
                path: 'product-list',
                name: 'product-list',
                component: ProductList,
                meta: {
                    title: 'Product List | Shoppu Dashboard',
                }
            },
            {
                path: 'product-detail',
                name: 'product-detail',
                component: ProductDetail,
                meta: {
                    title: 'Product Detail | Shoppu Dashboard',
                }
            },
        ]
    },
    {
        path: '/product',
        component: Body,
        children: [{
                path: 'category',
                name: 'Category',
                component: CategoryDigital,
                meta: {
                    title: 'Category | Shoppu Dashboard',
                }
            },
            {
                path: 'sub-category/:catId',
                name: 'sub-category',
                component: SubCategoryDigital,
                meta: {
                    title: 'SubCategory | Shoppu Dashboard',
                }
            },
            {
                path: 'product-list',
                name: 'product-list',
                component: ProductListDigital,
                meta: {
                    title: 'Product List | Shoppu Dashboard',
                }
            },
            {
                path: 'add-product',
                name: 'add-product',
                component: AddProductDigital,
                meta: {
                    title: 'Add product | Shoppu Dashboard',
                }
            },
        ]
    },
    {
        path: '/sales',
        component: Body,
        children: [{
                path: 'order',
                name: 'order',
                component: Order,
                meta: {
                    title: 'Order | Shoppu Dashboard',
                }
            },
            {
                path: 'transactions',
                name: 'transactions',
                component: transactions,
                meta: {
                    title: 'Transactions | Shoppu Dashboard',
                }
            },
        ]
    },
    {
        path: '/coupons',
        component: Body,
        children: [{
                path: 'coupon-list',
                name: 'coupon-list',
                component: CouponList,
                meta: {
                    title: 'Coupon | Shoppu Dashboard',
                }

            },
            {
                path: 'add-coupon',
                name: 'add-coupon',
                component: AddCoupon,
                meta: {
                    title: 'Coupon | Shoppu Dashboard',
                }

            },
        ]
    },
    {
        path: '/pages',
        component: Body,
        children: [{
                path: 'page-list',
                name: 'page-list',
                component: PageList,
                meta: {
                    title: 'Pages | Shoppu Dashboard',
                }
            },
            {
                path: 'create-page',
                name: 'create-page',
                component: CreatePage,
                meta: {
                    title: 'Pages | Shoppu Dashboard',
                }
            }
        ]
    },

    {
        path: '/menus',
        name: 'menus',
        component: Body,
        children: [{
                path: 'menu-list',
                name: 'menu-list',
                component: MenuList,
                meta: {
                    title: 'Menus | Shoppu Dashboard',

                }
            },
            {
                path: 'create-menu',
                name: 'create-menu',
                component: CreateMenu,
                meta: {
                    title: 'Menus | Shoppu Dashboard',

                }
            }
        ]
    },
    {
        path: '/users',
        name: 'users',
        component: Body,
        children: [{
                path: 'user-list',
                name: 'user-list',
                component: UserList,
                meta: {
                    title: 'User | Shoppu Dashboard',

                }
            },
            {
                path: 'create-user',
                name: 'create-user',
                component: CreateUser,
                meta: {
                    title: 'User | Shoppu Dashboard',

                }
            }
        ]
    },
    {
        path: '/vendors',
        name: 'vendors',
        component: Body,
        children: [{
                path: 'vendor-list',
                name: 'vendor-list',
                component: VendorList,
                meta: {
                    title: 'Vendor | Shoppu Dashboard',

                }
            },
            {
                path: 'create-vendor',
                name: 'create-vendor',
                component: CreateVendor,
                meta: {
                    title: 'Vendor | Shoppu Dashboard',

                }
            }
        ]
    },
    {
        path: '/localization',
        name: 'vendolocalizationrs',
        component: Body,
        children: [{
                path: 'translations',
                name: 'translations',
                component: LocalTranslation,
                meta: {
                    title: 'Localization | Shoppu Dashboard',

                }
            },
            {
                path: 'currency-rates',
                name: 'currency-rates',
                component: CurrencyRates,
                meta: {
                    title: 'Localization | Shoppu Dashboard',

                }
            },
            {
                path: 'taxes',
                name: 'taxes',
                component: Taxes,
                meta: {
                    title: 'Localization | Shoppu Dashboard',

                }
            }
        ]
    },

    {
        path: '/settings',
        name: 'settings',
        component: Body,
        children: [{
            path: 'profile',
            name: 'profile',
            component: Profile,
            meta: {
                title: 'Profile | Shoppu Dashboard',

            }
        }]
    },
    {
        path: '/auth/login',
        name: 'login',
        component: Auth,
        meta: {
            title: 'Login | Shoppu Dashboard',
        }
    },
    {
        path: '/auth/callback',
        name: 'auth',
        component: AuthCallback,
        meta: {
            title: 'Authentication | Shoppu Dashboard',
        }
    },
    {
        path: '/app',
        component: Body,
        children: [{
                path: 'media',
                name: 'media',
                component: Media,
                meta: {
                    title: 'Media | Shoppu Dashboard',
                }
            },
            {
                path: 'reports',
                name: 'reports',
                component: Reports,
                meta: {
                    title: 'Reports | Shoppu Dashboard',

                }
            },
            {
                path: 'invoice',
                name: 'invoice',
                component: Invoice,
                meta: {
                    title: 'Invoice | Shoppu Dashboard',

                }
            },
        ],
    }
]


const router = new VueRouter({
    mode: 'history',        
    routes
})

router.beforeEach((to, from, next) => {
    const path = ['/auth/login', '/register'];    
    if (path.includes(to.path) || to.path === "/auth/callback" || Userauth.isAuthenticatedUser()) {
        return next();
    }
    store.commit('setReturnUrl', to.fullPath)
    next('/auth/login')    
});

export default router