<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="Product" title="Category" />
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Category List</h5>
            </div>
            <div class="card-body">
              <CategoryModal ref="cat-dialog" />
              <div class="table-responsive datatable-vue product-physical">
                <b-modal ref="cat-delete-modal" id="modal-2" title="Confirmation" hide-footer>
                  <b-overlay :show="deleteLoading" rounded="sm">
                    <p class="my-4">Are you sure!</p>
                    <b-button class="mt-3" @click="deleteRow(selectedSku)" variant="primary">Delete</b-button>
                  </b-overlay>
                </b-modal>
                <b-overlay :show="loading" rounded="sm">
                <b-table
                  :select-mode="selectMode"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  show-empty
                  stacked="md"
                  :items="items"
                  :fields="tablefields"
                  :current-page="currentPage"
                  :per-page="0"
                  @row-selected="rowSelected"
                >
                  <template v-slot:head(actions)>
                    <feather
                      type="plus"
                      stroke="#3758FD"
                      stroke-width="6"
                      size="22px"
                      fill="#3758FD"
                    ></feather>
                  </template>
                  <template #cell(status)="field">
                    <statusIcon :field="field"></statusIcon>
                  </template>
                  <template #cell(image)="field">
                    <img
                      height="50px"
                      :src="getImgUrl(field.item.image?.url)"
                      width="50px"
                    />
                  </template>
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="edit != item.id">{{ value }}</template>
                    <b-form-input type="text" v-else v-model="item[key]" />
                  </template>
                  <template v-slot:cell(subcategory)="{ item: { id } }">
                    <b-button size="sm" :to="{ name: 'sub-category', params: {catId: id} }" class="mr-1">
                      Sub Category
                    </b-button>
                  </template>
                  <template v-slot:cell(actions)="{ item: item }">
                    <span
                      style="font-size: 20px; color: blue;"
                      v-b-modal.modal-1
                      @click="onEdit(item)"
                    >
                      <feather
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                      ></feather>
                    </span>
                    <span
                      v-b-modal.modal-2
                      @click="getIndex(item.id)"
                      style="font-size: 20px; color: #FA399F;"
                    >
                      <feather
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                      ></feather>
                    </span>
                  </template>
                  <template v-slot:head(actions)>

                  </template>
                </b-table>
              </b-overlay>
              </div>
              <b-col md="12" class="my-1 pagination-justify p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="changePage"
                  aria-controls="my-table"
                  class="mt-4"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import statusIcon from "../../components/featherIcons/status-icon.vue";
import CategoryModal from "./AddCategory.vue";
import track from '@/mixins/track';
export default {
  name: 'Category',
  mixins: [track],
  components: { statusIcon },
  data() {
    return {
      loading: false,
      deleteLoading: false,
      tablefields: [
        { key: "image", label: "image", sortable: false },
        { key: "title", label: "Title", sortable: false },
        { key: "subcategory", label: "Sub Category", sortable: false },
        { key: "actions" }
      ],
      selectMode: "single",
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      add: false,
      save: false,
      edit: null,
      item: "",
      selectedSku : "",
      index: null
    };
  },
  async created() {
    if(this.currentShop?.id)
       await this.loadCategoryList()
  },
  watch: {
    async currentShop(){
      if(this.currentShop?.id)
        await this.loadCategoryList()
    }
  },
  components: {
    CategoryModal
  },
  computed: {
    ...mapState({
      currentShop: state => state.shop?.currentShop,
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL,
      items: state => state.product?.categoryList?.data || [],
      paganiation: state => state.product?.categoryList?.pagination,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    selectedRows() {
      return this.items.filter(item => item.delete);
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.paganiation?.total || 0;
  },
  methods: {
    getImgUrl(path) {
      return path ? (path.indexOf('https://') != -1 ? path : `${this.BASE_IMAGE_URL}${path}`) : null
    },
    async loadCategoryList(){
      this.loading = true;
      await this.$store.dispatch("product/getCategoryList", {
      pagination: {page: this.currentPage, pageSize: this.perPage},
      filters: {shopId: this.currentShop?.id }
      });
      this.totalRows = this.paganiation?.total || 0;
      this.loading = false;
    },
    async changePage(page){
      this.currentPage = page;
      await this.loadCategoryList();
    },
    getIndex(id) {
      this.selectedSku = id
    },
    formData(e) {
      console.log("modal data", e);
    },
    onEdit(item) {
      this.$refs['cat-dialog']?.fillForm(item)
    },
    async deleteRow(id) {
      this.deleteLoading = true;
      const result = await this.$store.dispatch("category/deleteCategory", {
        id: id
      });
      this.deleteLoading = false;
      this.loadCategoryList();
      this.$refs['cat-delete-modal'].hide();

    },
    rowSelected(item) {
      if (item[0]) {
        this.edit = this.edit !== item[0].id ? item[0].id : null;
      }
    },
    searchData() {
      this.add = false;
      this.items.splice(0, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
</style>