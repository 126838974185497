<template>
  <div class="btn-popup pull-right">
    <b-button v-b-modal.modal-product-1 variant="primary" @click="clearForm()">{{ productType == 'create' ? 'Add Product' : 'Update Product' }}</b-button>
    <b-modal
      id="modal-product-1"
      ref="product-modal"
      :title="productType == 'create' ? 'Add Product' : 'Update Product'"
      hide-footer
      size="lg"
      ok-variant="primary"
      class="product-modal"
    >
    <div class="container-fluid">
      <b-overlay :show="loading" rounded="sm">
        <div class="row product-adding">
         <!-- start -->
          <div class="col-xl-6">
            <div class="card">
              <div class="card-header">
                <h5>General</h5>
              </div>
              <div class="card-body">
                  <div class="digital-add needs-validation">
                    <div class="form-group">
                      <label
                        for="validationCustom01"
                        class="col-form-label pt-0"
                        ><span>*</span> Title</label
                      >
                      <ValidationProvider
                        name="title"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <input
                          class="form-control"
                          id="validationCustom01"
                          type="text"
                          v-model="form.title"
                          required=""
                        />
                        <span
                          class="block text-danger text-xs absolute bottom-0 left-0"
                          >{{ errors[0] }}</span
                        >
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label"
                        ><span>*</span> Categories</label
                      >
                      <b-form-select style="margin-bottom:20px" @change="loadSubCategoryList" v-model="form.category" :options="categories" size="sm" class="mt-3">
                      </b-form-select>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label"
                        ><span>*</span>Sub Categories</label
                      >
                      <b-form-select style="margin-bottom:20px" v-model="form.subCategory" :options="subCategories" size="sm" class="mt-3">
                      </b-form-select>
                    </div>
                    <div class="form-group">
                      <label for="validationCustom02" class="col-form-label"
                        ><span>*</span> Product Price</label
                      >
                      <input
                        class="form-control"
                        id="validationCustom02"
                        type="text"
                        required=""
                        v-model="form.price"
                      />
                    </div>
                    <b-overlay :show="uploadLoading" rounded="sm">
                      <label for="validationCustom02" class="mb-1"
                        >Product Image :</label
                      >
                      <input class="form-control" accept="image/*" type="file" @change="uploadPhoto" />
                      <div v-if="imageUploaded?.url" class="col-xl-4 xl-50 col-sm-4 col-4">
                        <img
                          :src="getImgUrl(imageUploaded.url)"
                          class="img-fluid image_zoom_1 blur-up lazyloaded"
                        />
                      </div>
                    </b-overlay>

                  </div>
                  <!-- form end working -->
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card">
              <div class="card-header">
                <h5>Add Description</h5>
              </div>
              <div class="card-body">
                <div class="digital-add needs-validation">
                  <div class="md-12">
                    <textarea rows="8" cols="12" v-model="form.description"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="card">
              <div class="card-header">
                <h5>Meta Data</h5>
              </div>
              <div class="card-body">
                <div class="digital-add needs-validation">
                  <div class="form-group">
                    <label for="validationCustom05" class="col-form-label pt-0">
                      Meta Title</label
                    >
                    <input
                      class="form-control"
                      id="validationCustom05"
                      type="text"
                      required=""
                    />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Meta Description</label>
                    <textarea rows="4" cols="12"></textarea>
                  </div>
                  <div class="form-group mb-0">
                    <div class="product-buttons text-center">
                      <button type="button" class="btn btn-primary">
                        Add
                      </button>
                      <button type="button" class="btn btn-light ml-1">
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        </b-overlay>
    </div>
    <!-- Container-fluid Ends-->
      <b-button class="mt-3"  @click="$bvModal.hide('product-modal')" variant="primary">Cancel</b-button>
      <b-button class="mt-3" style="margin-left:10px"  @click="onSave()" >Save</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import track from '@/mixins/track';
export default {
  name: 'AddProduct',
  mixins: [track],
  data() {
    return {
      productType: 'create',
      uploadLoading: false,
      loading: false,
      form: {
        title: null,
        category: null,
        subCategory: null,
        price: null,
        description: null
      },
      productId: null,
      imageUploaded: null
    };
  },
  computed: {
    ...mapState({
      currentShop: state => state.shop?.currentShop,
      categories: state => [{ value: null, text: 'All Categories' }, ...state.product?.categoryList?.data?.map(item => { return { value: item.id, text: item.title }})],
      subCategories: state => [{ value: null, text: 'All Sub Categories' }, ...state.product?.subCategoryList?.data?.map(item => { return { value: item.id, text: item.title }})],
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL
    })
  },
  methods: {
    clearForm() {
      this.productType = 'create';
      this.productId = null;
      this.form.title = null;
      this.form.description = null;
      this.form.price = null;
      this.form.shop = this.currentShop?.id;
      this.form.images = [];
      this.form.category = null;
      this.form.categories = [];
      this.form.subCategory = null;
      this.form.sub_categories = [];
      this.imageUploaded = null;

    },
    fillForm(item) {
      console.log(item);
      console.log(this.categories);
      this.productType = 'update';
      this.productId = item.id;
      this.form.title = item.title;
      this.form.description = item.description;
      this.form.price = item.price;
      this.form.shop = item.shop?.id;
      this.form.images = item.images?.[0]?.id ? [parseInt(item.images[0].id)] : [];
      this.form.category = item.categories?.[0]?.id || null;
      this.form.categories = item.categories?.[0]?.id ? [parseInt(item.categories[0].id)] : [];
      this.form.subCategory = item.sub_categories?.[0]?.id || null;
      this.form.sub_categories = item.sub_categories?.[0]?.id ? [parseInt(item.sub_categories[0].id)] : [];
      this.imageUploaded = null;
      this.imageUploaded = item.images?.[0];
      console.log(this.form)
    },
    getImgUrl(path) {
      return path ? (path.indexOf('https://') != -1 ? path : `${this.BASE_IMAGE_URL}${path}`) : null
    },
    async loadSubCategoryList(){
      this.form.subCategory = null;
      await this.$store.dispatch("product/getSubCategoryList", {
      pagination: {page: 1, pageSize: 100},
      filters: {
        catId: this.form?.category?.id
       }
     });
    },
    async uploadPhoto({ target: { files = [] } }) {
      if (!files.length) {
          return
      }
      let file = files[0];
      this.uploadLoading = true;
      try {
        this.imageUploaded = await this.$store.dispatch("upload/uploadImage", {
          file: file
        });
        if(this.imageUploaded?.id)
          this.form.images = [this.imageUploaded.id];

      } catch (error) {
        console.error(error)
      }
      this.uploadLoading = false;

    },
    async onSave() {
        this.loading = true;
        if(this.productType == 'create') {
          await this.$store.dispatch("product/createProduct", {
            title: this.form.title,
            price: parseInt(this.form.price),
            description: this.form.description,
            images: this.form.images || [],
            categories: this.form.category ? [parseInt(this.form.category)] : [],
            sub_categories: this.form.subCategory ? [parseInt(this.form.subCategory)] : [],
            shop: this.currentShop?.id
          });
        }else{
          await this.$store.dispatch("product/updateProduct", {
            id: this.productId,
            data: {
              title: this.form.title,
              price: parseInt(this.form.price),
              description: this.form.description,
              images: this.form.images || [],
              categories: this.form.category ? [parseInt(this.form.category)] : [],
              sub_categories: this.form.subCategory ? [parseInt(this.form.subCategory)] : []
            }
          });
        }

        await this.$store.dispatch("product/getProductList", {
          pagination: {page: 1, pageSize: 20},
          filters: {
            shopId: this.currentShop?.id,
            ...this.categorySelected ? { catId: this.form.category?.id } : {},
            ...this.subCategorySelected ? { subCatId: this.form.subCategory?.id } : {}
          }
          });
        this.$refs['product-modal'].hide();
        this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
 .ck-content { height:500px; }

</style>
<style lang="scss">
#modal-product-1 {
  .modal-dialog{
    max-width: 90% !important;
  }
}
</style>