import { apolloClient } from '@/apollo/client'
import productListQuery from '@/apollo/query/product/products.gql'
import categoryListQuery from '@/apollo/query/product/categories.gql'
import subCategoryListQuery from '@/apollo/query/product/subCategories.gql'
import createProductMutation from '@/apollo/mutation/product/createProduct.gql'
import updateProductMutation from '@/apollo/mutation/product/updateProduct.gql'
import deleteProductMutation from '@/apollo/mutation/product/deleteProduct.gql'

const state = () => {
  return {
    productList: {
      pagination: {},
      data: []
    },
    categoryList: {
      pagination: {},
      data: []
    },
    subCategoryList: {
      pagination: {},
      data: []
    },
    currency: {
        curr: 'usd',
        symbol: '$'
    },
    loadingStatus: false
  }
}
const getters = {    
    getCategoryList: (state) => {
        return state.categoryList;
    },
    getSubCategoryList: (state) => {
        return state.subCategoryList;
    },
    getProductList: (state) => {
        return state.productList;
    },    
    loadingStatus: (state) => {
        return state.loadingStatus;
    },
    changeCurrency: (state) => {
        if (state.currency.curr === 'eur') {
            state.currency.curr = 0.90
            return state.currency
        } else if (state.currency.curr === 'inr') {
            state.currency.curr = 70.93
            return state.currency
        } else if (state.currency.curr === 'gbp') {
            state.currency.curr = 0.78
            return state.currency
        } else if (state.currency.curr === 'usd') {
            state.currency.curr = 1
            return state.currency
        }
    },
}
const actions = {   
  async createProduct(context, payload) {
    let data = await apolloClient.mutate({ 
      mutation: createProductMutation, 
      variables: { data: payload } 
    }).then(({ data }) => data && data?.createProduct?.data)
        return {
          id: parseInt(data?.id),
          ...data?.attributes,
        };
    },
    async updateProduct(context, payload) {
      let { id, data } = payload;
      let result = await apolloClient.mutate({ 
        mutation: updateProductMutation, 
        variables: { id: id, data: data } 
      }).then(({ data }) => data && data?.updateProduct?.data)
      return {
        id: parseInt(result?.id),
        ...result?.attributes,
      };
    },
    async deleteProduct(context, payload) {
      let { id } = payload;
      let data = await apolloClient.mutate({ 
        mutation: deleteProductMutation, 
        variables: { id: id } 
      }).then(({ data }) => data && data?.deleteProduct?.data)
      return {
        id: parseInt(data?.id),
        ...data?.attributes,
      };
    }, 
    async getProductList(context, payload) {               
        const { pagination, filters } = payload
        let data = await apolloClient.query({ 
          query: productListQuery, 
          variables: { 
            page: pagination.page, 
            pageSize: pagination.pageSize, 
            ...filters
        }, 
          fetchPolicy: 'no-cache' 
        }).then(({ data }) => data && data?.products)    
        context.commit('getProductList', data)
    },    
    async getCategoryList(context, payload) {               
        const { pagination, filters } = payload
        let data = await apolloClient.query({ 
          query: categoryListQuery, 
          variables: { page: pagination.page, pageSize: pagination.pageSize, shopId: filters.shopId}, 
          fetchPolicy: 'no-cache' 
        }).then(({ data }) => data && data?.categories)    
        context.commit('getCategoryList', data)
    },    
    async getSubCategoryList(context, payload) {               
        const { pagination, filters } = payload
        let data = await apolloClient.query({ 
          query: subCategoryListQuery, 
          variables: { page: pagination.page, pageSize: pagination.pageSize, catId: filters.catId}, 
          fetchPolicy: 'no-cache' 
        }).then(({ data }) => data && data?.subCategories)    
        context.commit('getSubCategoryList', data)
    },    
    changeCurrency: (context, payload) => {
        context.commit('changeCurrency', payload)
    },
}
const mutations = {
    loadingStatus: (state, payload) => {
        state.loadingStatus = payload;
    },    
    getProductList: (state, result) => {    
        state.productList = {
          pagination: {},
          data: []
        }
        if (result?.data?.length) {      
          state.productList.pagination = result.meta.pagination;
          state.productList.data = result.data.map(item => {        
            return {
              id: parseInt(item.id),
              ...item.attributes,
              ...item.attributes?.images?.data ? { images: [...item.attributes.images.data.map(p => { return {id:parseInt(p.id), ...p.attributes} })] } : {},
              ...item.attributes?.categories?.data ? { categories: [...item.attributes.categories.data.map(p => { return {id: parseInt(p.id), ...p.attributes} })] } : {},
              ...item.attributes?.tags?.data ? { tags: [...item.attributes.tags.data.map(p => { return {id: parseInt(p.id), ...p.attributes} })] } : {},
              ...item.attributes?.shop?.data ? { shop: {id: parseInt(item.attributes.shop.data.id), ...item.attributes.shop.data.attributes}} : {}
            }
          })      
        }
    },    
    getCategoryList: (state, result) => {    
        state.categoryList = {
          pagination: {},
          data: []
        }
        if (result?.data?.length) {   
          state.categoryList.pagination = result.meta.pagination;   
          state.categoryList.data = result.data.map(item => {        
            return {
              id: parseInt(item.id),
              ...item.attributes,
              ...item.attributes?.image?.data ? { image: {id: item.attributes.image.data.id, ...item.attributes.image.data.attributes}} : {},
              ...item.attributes?.sub_categories?.data ? { sub_categories: [...item.attributes.sub_categories.data.map(p => { return {id: parseInt(p.id), ...p.attributes} })] } : {}              
            }
          })      
        }
    },    
    getSubCategoryList: (state, result) => {    
        state.subCategoryList = {
          pagination: {},
          data: []
        }
        if (result?.data?.length) { 
          state.subCategoryList.pagination = result.meta.pagination;     
          state.subCategoryList.data = result.data.map(item => {        
            return {
              id: parseInt(item.id),
              ...item.attributes,
              ...item.attributes?.category?.data ? { category: {id: item.attributes.category.data.id, ...item.attributes.category.data.attributes}} : {}
            }
          })      
        }
    },    
    changeCurrency: (state, payload) => {
        state.currency = payload
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}