const SITE_CONFIG = require(`./${process.env.VUE_APP_SITE_CONFIG}`);
const config = {
  loginUrl: SITE_CONFIG.VUE_LOGIN_URL + encodeURI(SITE_CONFIG.VUE_CALLBACK_URL),
  APP_URL: SITE_CONFIG.APP_URL,
  BASE_IMAGE_URL: SITE_CONFIG.BASE_IMAGE_URL,
  BASE_URL: SITE_CONFIG.BASE_URL,
  APOLLO_GRAPHQL_URL: SITE_CONFIG.APOLLO_GRAPHQL_URL,
  TRACK: SITE_CONFIG.TRACK,
  WEBMMS: SITE_CONFIG.WEBMMS,
}
export default config;
