<template>
  <div class="btn-popup pull-right">
    <b-button v-b-modal.modal-1 variant="primary" @click="clearForm()">{{ subCategoryType == 'create' ? 'Add SubCategory' : 'Update SubCategory' }}</b-button>
    <b-modal
      id="modal-1"
      ref="subcat-modal"
      :title="subCategoryType == 'create' ? 'Add SubCategory' : 'Update SubCategory'"
      hide-footer
      ok-variant="primary"
    >
      <form class="needs-validation">
        <b-overlay :show="loading" rounded="sm">
        <div class="form">
          <div class="form-group">
            <label for="validationCustom01" class="mb-1">SubCategory Title :</label>
            <input
              class="form-control"
              id="validationCustom01"
              type="text"
              v-model="title"
            />
          </div>         
        </div>
      </b-overlay>
      </form>
      <b-button class="mt-3" block @click="$bvModal.hide('subcat-modal')" variant="primary">Cancel</b-button>
      <b-button class="mt-3" block @click="onSave()" >Save</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GlobalSubCategoryModal",
  data() {
    return {
      loading: false,
      subCategoryType: 'create',      
      catId: null,
      subCatId: null,
      title: null      
    };
  },
  computed: {
    ...mapState({            
      APP_URL: state => state.APP_URL
    }),
  },
  methods: {
    clearForm() {
      this.subCategoryType = 'create';
       this.subCatId = null;
       this.title = null;
       this.catId = this.$route.params.catId;       
    },
    fillForm(item) {      
      this.subCategoryType = 'update';
      this.subCatId = item.id;
      this.title = item.title;
      this.catId = item.category?.id;      
    },
    async onSave() {      
        this.loading = true;
        if(this.subCategoryType == 'create') {
          await this.$store.dispatch("subcategory/createSubCategory", {
            title: this.title,            
            category: this.catId
          });      
        }else{
          await this.$store.dispatch("subcategory/updateSubCategory", {
            id: this.subCatId,
            data: {
              title: this.title,              
              category: this.catId
            }
          });      
        }
        
        this.$store.dispatch("product/getSubCategoryList", {
          pagination: {page: 1, pageSize: 10}, 
          filters: {catId: this.catId }
        });
        this.$refs['subcat-modal'].hide();
        this.loading = false;
    }
  }
};
</script>

<style>

</style>