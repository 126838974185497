<template>
  <div>
      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="page-header">
          <Breadcrumbs main="product" title="Product List" />
        </div>
      </div>
      <!-- Container-fluid Ends-->

      <!-- Container-fluid starts-->
      <div class="container-fluid">
        <div class="row products-admin ratio_asos">
          <div
            class="col-xl-3 col-sm-6"
            v-for="(product, index) in products"
            :key="index"
          >
            <div class="card">
              <div class="card-body product-box ">
                <div class="img-wrapper">
                  <div class="front">
                    <a href="#">
                      <img
                        :src="getImgUrl(product.images?.[0]?.url)"
                        class="img-fluid blur-up lazyload bg-img"
                        alt=""
                      />
                    </a>
                    <div class="product-hover">
                      <ul>
                        <li>
                          <button
                            class="btn"
                            type="button"
                            data-original-title=""
                            title=""
                          >
                            <i class="ti-pencil-alt">
                              <feather type="edit"></feather>
                            </i>
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn"
                            type="button"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            data-original-title=""
                            title=""
                          >
                            <i class="ti-trash">
                              <feather type="trash-2"></feather>
                            </i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="product-detail">
                  <div class="rating">
                  <StarRating
                    :show-rating="false"
                    :star-size="15"
                    :rounded-corners="true"
                    :padding="4"
                    :rating="2"
                    :read-only="true"
                  ></StarRating>
                   
                  </div>
                  <a href="#">
                    <h6>{{ product.title }}</h6>
                  </a>
                  <h4>
                    {{ product.price | currency('$', 0) }} <del>{{ product.salePrice | currency('$', 0) }}</del>
                  </h4>
                  <!-- <ul class="color-variant">
                    <li class="bg-light0"></li>
                    <li class="bg-light1"></li>
                    <li class="bg-light2"></li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  async created() {
    
  },
  watch: {
    currentShop(){
      if(this.currentShop?.id)
        this.loadProductList()
    }
  },
  computed: {
    ...mapGetters({
      products: "product/getProductList"      
    }),
    ...mapState({
      currentShop: state => state.shop?.currentShop,
      APP_URL: state => state.APP_URL,
      BASE_IMAGE_URL: state => state.BASE_IMAGE_URL,
    })
  },
  methods: {
    getImgUrl(path) {
      return path ? (path.indexOf('https://') != -1 ? path : `${this.BASE_IMAGE_URL}${path}`) : null
    },
    async loadProductList(){
      await this.$store.dispatch("product/getProductList", {
      pagination: {page: 1, pageSize: 10}, 
      filters: {shopId: this.currentShop?.id }
    });
    }
  }
};
</script>

<style>

</style>