import Vue from 'vue';
import webmms from "webmms-client";
import { set as setCookie, get as getCookie, remove as removeCookie } from "es-cookie";
import site_config from "../config/config";
import store from '@/store';

var makeId = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

var mmsReady = false;

Vue.prototype.$mmsStatus = { mmsReady: mmsReady };
var mms = webmms({
  wsurl: site_config?.WEBMMS?.GATE_URL || window.location.hostname,
  EiToken: getCookie("apps-EiToken") || "",
  SToken: getCookie("apps-SToken") || ""
});

mms.on("registered", async reply => {
  // console.log('reply=%s', JSON.stringify(reply))
  let eiName = '';
  if (reply.ErrMsg == "OK") {
    eiName = reply.result.EiName ? reply.result.EiName : 'AIM' + makeId(5);
  }

  setCookie("apps-EiToken", reply.result.EiToken);
  setCookie("apps-SToken", reply.result.SToken);

  console.log('regtoCenter: %s', reply.ErrMsg);
  let deviceInfo = { "DDN": reply.result.DDN, "EiOwner": "", "EiName": eiName, "EiType": ".web", "EiTag": '', "EiLoc": "" };
  let result = await mms.setDeviceInfo(deviceInfo);
   console.log('SetDevice reply=%s', JSON.stringify(result));
  mms.$MMSReady = true;
  Vue.prototype.$mmsStatus.mmsReady = true;
  store.commit("changeMMSReady", true);
});

mms.on('message', (method, from, data, body) => {
  mms.$MMSReady = true;
  mmsReady = true;
  // console.log('rcve: from=%s, data=%s', from, JSON.stringify(data));
  if (typeof data == 'object' && !data.from)
    data.from = from;
  if (data) {

  }
});

mms.on('state', msg => {
  mms.$MMSReady = false;
  Vue.prototype.$mmsStatus.mmsReady = false;
  store.commit("changeMMSReady", false);
});

mms.on('disconnect', msg => {
  mms.$MMSReady = false;
  Vue.prototype.$mmsStatus.mmsReady = false;
  store.commit("changeMMSReady", false);
});

mms.on('error', err => {
  mms.$MMSReady = false;
  Vue.prototype.$mmsStatus.mmsReady = false;
  store.commit("changeMMSReady", false);
});

mms.on('connect_error', err => {
  mms.$MMSReady = false;
  Vue.prototype.$mmsStatus.mmsReady = false;
  store.commit("changeMMSReady", false);
});

mms.on('connect_timeout', err => {
  mms.$MMSReady = false;
  Vue.prototype.$mmsStatus.mmsReady = false;
  store.commit("changeMMSReady", false);
});

export default mms;