<template>
  <form
    class="form-horizontal auth-form"
    @submit.prevent="handleSubmit"
    method="post"
  >
    
    
    <div class="form-button">
      <button class="btn btn-primary" type="submit" @click="login">
        Login
      </button>
    </div>
    <div class="form-footer">
      
    </div>
  </form>
</template>

<script>
import conf from'@/config/config';

export default {
  components: {},
  data() {
    return {
      LoginUrl: conf.loginUrl
    };
  },
  created() {
    // reset login status for JWT
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
    },
    login() {
      window.location.href = this.LoginUrl;
    }
  }
};
</script>