<template>
  <div class="loading fill-height" v-if="loading">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12" style="text-align:center">
                <p class="loading_text" :v-if="authLoading">Please wait for authentication...</p>                
                <b-spinner variant="primary" type="grow" label="Spinning" :v-if="authLoading">
                </b-spinner>
           </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
data() {
  return {
    loading: true,
    authLoading: true,    
    oauthParams : {},
    returnUrl: null
  }
},
async created() {
    this.readQueryParam();
    this.storeUserInfo();
    //this.startMMS();
},
methods : {
    readQueryParam(){
       let { data } = this.$route.query;
       try {
         if(data)
          this.oauthParams = JSON.parse(data);
         this.returnUrl = this.$store.getters.returnUrl;
       } catch (error) {         
         this.$toasted.show("Account data is not valid", {
            theme: "bubble",
            position: "bottom-right",
            type: "error",
            duration: 2000
          });
       }

    },
    storeUserInfo(){
      if(this.oauthParams.errMsg == 'OK'){
        this.$store.dispatch('authentication/login',this.oauthParams.data);
      }

      this.$store.dispatch('clearReturnUrl');
      this.$router.push(this.returnUrl || '');
    }

}
}
</script>
<style scoped>
.loading{
position: absolute;
width: 100%;
height: 100%;
top: 0;
left:0;
z-index: 50;
background-color: rgba(255, 255, 255, 0.4196078431372549);
}
.loading_text{
    font-weight: bold;
  color: #000;
  font-size: 11pt;
}
</style>
